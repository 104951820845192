export const close = c => m.tw("svg[fill='currentColor'][viewBox='0 0 20 20'][xmlns='http://www.w3.org/2000/svg']",
  m("path[fill-rule='evenodd'][d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'][clip-rule='evenodd']"))(c)

export const hamburger = c => m.tw("svg[fill='currentColor'][viewBox='0 0 20 20'][xmlns='http://www.w3.org/2000/svg']",
  m("path[fill-rule='evenodd'][d='M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z'][clip-rule='evenodd']"))(c)

export const piechart = c => m.tw("svg", { "fill": "currentColor", "viewBox": "0 0 20 20", "xmlns": "http://www.w3.org/2000/svg" },
  [
    m("path", { "d": "M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" }),
    m("path", { "d": "M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" })
  ]
)(c)

export const tiles = c => m.tw("svg", { "fill": "currentColor", "viewBox": "0 0 20 20", "xmlns": "http://www.w3.org/2000/svg" },
  m("path", { "d": "M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" })
)(c)

export const inbox = c => m.tw("svg", { "fill": "currentColor", "viewBox": "0 0 20 20", "xmlns": "http://www.w3.org/2000/svg" },
  [
    m("path", { "d": "M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z" }),
    m("path", { "d": "M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z" })
  ]
)(c)

export const upload = c => m.tw("svg", { "fill": "none", "stroke": "currentColor", "viewBox": "0 0 24 24", "xmlns": "http://www.w3.org/2000/svg" },
  m("path", { "stroke-linecap": "round", "stroke-linejoin": "round", "stroke-width": "2", "d": "M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" })
)(c)

export const chevronRight = c => m.tw("svg", { "fill": "currentColor", "viewBox": "0 0 20 20", "xmlns": "http://www.w3.org/2000/svg" },
  m("path", { "fill-rule": "evenodd", "d": "M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z", "clip-rule": "evenodd" })
)(c)


// https://materialdesignicons.com/
export const text = c => m.tw("svg", {"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24"},
m("path", {"fill":"currentColor","fill-rule":"evenodd","d":"M18 5.5h-5.25V18c0 .28.22.5.5.5h2a.75.75 0 1 1 0 1.5h-6.5a.75.75 0 1 1 0-1.5h2a.5.5 0 0 0 .5-.5V5.5H6a.5.5 0 0 0-.5.5v1.25a.75.75 0 0 1-1.5 0V5.5C4 4.67 4.67 4 5.5 4h13c.83 0 1.5.67 1.5 1.5v1.75a.75.75 0 1 1-1.5 0V6a.5.5 0 0 0-.5-.5z"})
)(c)

export const grid = c => m.tw("svg[xmlns='http://www.w3.org/2000/svg'][classname='h-5 w-5'][viewBox='0 0 20 20'][fill='currentColor']",
  m("path[d='M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z']")
)(c)

export const keyframes = {
  add: c => m.tw("svg[width='24'][height='24'][stroke-width='1.5'][viewBox='0 0 24 24'][fill='none'][xmlns='http://www.w3.org/2000/svg']",
    [
      m("path[d='M18.8189 13.3287L13.4948 19.3183C12.6992 20.2134 11.3008 20.2134 10.5052 19.3183L5.18109 13.3287C4.50752 12.571 4.50752 11.429 5.18109 10.6713L10.5052 4.68167C11.3008 3.78664 12.6992 3.78664 13.4948 4.68167L18.8189 10.6713C19.4925 11.429 19.4925 12.571 18.8189 13.3287Z'][stroke='currentColor'][stroke-linecap='round'][stroke-linejoin='round']"),
      m("path[d='M9 12H12M15 12H12M12 12V9M12 12V15'][stroke='currentColor'][stroke-linecap='round'][stroke-linejoin='round']")
    ]
  )(c),
  couple: c => m.tw("svg[width='24'][height='24'][stroke-width='1.5'][viewBox='0 0 24 24'][fill='none'][xmlns='http://www.w3.org/2000/svg']",
    [
      m("path[d='M15.8189 13.3287L10.4948 19.3183C9.69924 20.2134 8.30076 20.2134 7.50518 19.3183L2.18109 13.3287C1.50752 12.571 1.50752 11.429 2.18109 10.6713L7.50518 4.68167C8.30076 3.78664 9.69924 3.78664 10.4948 4.68167L15.8189 10.6713C16.4925 11.429 16.4925 12.571 15.8189 13.3287Z'][stroke='currentColor'][stroke-linecap='round'][stroke-linejoin='round']"),
      m("path[d='M12 6.375L13.5052 4.68167C14.3008 3.78664 15.6992 3.78664 16.4948 4.68167L21.8189 10.6713C22.4925 11.429 22.4925 12.571 21.8189 13.3287L16.4948 19.3183C15.6992 20.2134 14.3008 20.2134 13.5052 19.3183L12 17.625'][stroke='currentColor'][stroke-linecap='round'][stroke-linejoin='round']")
    ]
  )(c),
  single: c => m.tw("svg[width='24'][height='24'][stroke-width='1.5'][viewBox='0 0 24 24'][fill='none'][xmlns='http://www.w3.org/2000/svg']",
    [
      m("path[d='M18.8189 13.3287L13.4948 19.3183C12.6992 20.2134 11.3008 20.2134 10.5052 19.3183L5.18109 13.3287C4.50752 12.571 4.50752 11.429 5.18109 10.6713L10.5052 4.68167C11.3008 3.78664 12.6992 3.78664 13.4948 4.68167L18.8189 10.6713C19.4925 11.429 19.4925 12.571 18.8189 13.3287Z'][stroke='currentColor'][stroke-linecap='round'][stroke-linejoin='round']"),
    ]
  )(c),
  remove: c => m.tw("svg[width='24'][height='24'][stroke-width='1.5'][viewBox='0 0 24 24'][fill='none'][xmlns='http://www.w3.org/2000/svg']",
    [
      m("path[d='M18.8189 13.3287L13.4948 19.3183C12.6992 20.2134 11.3008 20.2134 10.5052 19.3183L5.18109 13.3287C4.50752 12.571 4.50752 11.429 5.18109 10.6713L10.5052 4.68167C11.3008 3.78664 12.6992 3.78664 13.4948 4.68167L18.8189 10.6713C19.4925 11.429 19.4925 12.571 18.8189 13.3287Z'][stroke='currentColor'][stroke-linecap='round'][stroke-linejoin='round']"),
      m("path[d='M9 12H12H15'][stroke='currentColor'][stroke-linecap='round'][stroke-linejoin='round']")
    ]
  )(c)
}

export const caret = {
  left: c => m.tw("svg.bi.bi-caret-left-fill[xmlns='http://www.w3.org/2000/svg'][width='16'][height='16'][fill='currentColor'][viewBox='0 0 16 16']",
    m("path[d='m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z']")
  )(c),
  right: c => m.tw("svg.bi.bi-caret-right-fill[xmlns='http://www.w3.org/2000/svg'][width='16'][height='16'][fill='currentColor'][viewBox='0 0 16 16']",
    m("path[d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z']")
  )(c)
}

export const chevron = {
  left: c => m.tw("svg.w-6.h-6[fill='none'][stroke='currentColor'][viewBox='0 0 24 24'][xmlns='http://www.w3.org/2000/svg']",
    m("path[stroke-linecap='round'][stroke-linejoin='round'][stroke-width='2'][d='M15 19l-7-7 7-7']")
  )(c),
  right: c => m.tw("svg.w-6.h-6[fill='none'][stroke='currentColor'][viewBox='0 0 24 24'][xmlns='http://www.w3.org/2000/svg']",
    m("path[stroke-linecap='round'][stroke-linejoin='round'][stroke-width='2'][d='M9 5l7 7-7 7']")
  )(c)
  // left: c => m.tw("svg.bi.bi-chevron-left[xmlns='http://www.w3.org/2000/svg'][width='16'][height='16'][fill='currentColor'][viewBox='0 0 16 16']",
  //   m("path[fill-rule='evenodd'][d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z']")
  // )(c),
  // right: c => m.tw("svg.bi.bi-chevron-right[xmlns='http://www.w3.org/2000/svg'][width='16'][height='16'][fill='currentColor'][viewBox='0 0 16 16']",
  //   m("path[fill-rule='evenodd'][d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z']")
  // )(c),
}