import { spinner } from "./flowbite/spinner"
import { pager } from "./pager"

const default_empty_view = result => m("span", "empty state")

const default_loading_view = result => m("div.text-xl.flex.justify-center.items-center.text-gray-700",
  spinner(),
  m("span.ml-1", "Loading...")
)

export const data_pager = (result, config = {}) => {
  const {
    data_view,
    empty_view = default_empty_view,
    loading_view = default_loading_view,
    onnav
  } = config

  return [
    result ? [
      data_view(result.data),
      result.total === 0 && empty_view(result),
      result.total > result.limit && pager(result, { onnav }),
    ] : loading_view(result),
  ]
}