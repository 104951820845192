import { sec2ts, ts2sec } from "../../utils/video"
import hotkeys from "hotkeys-js"
import _ from "lodash"

const origHotkeyFilter = hotkeys.filter
hotkeys.filter = (event) => {
  const target = event.target || event.srcElement;
  if (target.matches('[data-enable-hotkeys]')) return true
  return origHotkeyFilter(event)
}

const unit = (config = {}) => {
  const {
    digits = 2,
    value = 0,
    delta,
    active,
    oninput = console.log
  } = config
  return m('span.unit.is-family-monospace', {
    class: active && 'is-active',
    'data-delta': delta,
    'data-digits': digits,
  }, m('span.value', (value || _.isFinite(value)) ?
    _.padStart(value + '', digits, '0') :
    _.padStart('', digits, '-')
  ),
    m('input.frag.w-0.h-0', {
      'data-enable-hotkeys': true,
      type: 'number',
      onkeydown: e => {
        if(['ArrowUp', 'ArrowDown', 'Tab'].includes(e.code)) {
          e.preventDefault()
        }
      },
      oninput: e => {
        const value = e.target.value
        if(value.length > digits) {
          e.target.value = value[value.length - 1] // value.slice(value.length - digits, value.length)
        }
        oninput(
          e,
          _.padStart(e.target.value + '', digits, '0'),
          e.target.closest('.unit').querySelector('.value')
        )
      }
    })
  )
}

export const TimestampInputInner = ({ redraw = m.redraw }) => {
  let time = 0
  const options = { capture: true }
  const range = {}
  return {
    onbeforeupdate: (vnode, old) => {
      const {
        value,
        min = 0,
        max = Infinity,
      } = vnode.attrs
      range.min = min
      range.max = max
      if (value && old.value !== value) time = Math.round(value)
    },
    oncreate: vnode => {
      const {
        value,
        defaultValue = value,
        min = 0,
        max = Infinity,
        onchange = _.devlog,
      } = vnode.attrs
      time = defaultValue
      range.min = min
      range.max = max

      const increment = (target, sign = 1) => {
        if (target.matches('.unit')) {
          const delta = parseInt(target.getAttribute('data-delta'))
          time = _.clamp(time + delta * sign, range.min, range.max)
          onchange(time)
          redraw()
        }
      }
      const select = (unit) => {
        const classes = [
          'is-active',
          'bg-blue-300'
        ].join(' ')
        $('.timestamp-input .unit').removeClass(classes)
        $(unit).addClass(classes)

        // clear hidden inputs, focus selected one
        $('.timestamp-input .unit input').val('')
        unit && $(unit).find('input').get(0).focus()
      }

      $(document).on('click', e => {
        const unit = e.target.closest('.unit')
        unit ? select(unit) : select()
      })
      vnode.dom.onkeydown = e => {
        let matched = false
        if(['ArrowUp', 'ArrowDown'].includes(e.code)) {
          const target = vnode.dom.querySelector('.is-active')
          target && increment(target, e.code === 'ArrowUp' ? 1 : -1)
          matched = true
        }
        if (['ArrowLeft', 'ArrowRight'].includes(e.code)) {
          const targets = $('.timestamp-input .unit:visible').get()
          const idx = targets.findIndex(t => t.classList.contains('is-active'))
          const delta = e.code === 'ArrowRight' ? 1 : -1
          select(targets[(idx + delta + targets.length) % targets.length])
          matched = true
        }
        if (matched) {
          e.stopPropagation()
        }
      }

      redraw()
    },
    view: vnode => {
      const {
        name,
        onchange = _.devlog,
      } = vnode.attrs
      const sec = time / 1000
      const ts = _.isFinite(sec) ? sec2ts(sec, true) : '00::.'
      const [hours, minutes, remaining] = ts.split(':')
      const [seconds, millis] = remaining.split('.')
      const setTime = (e, value, tgt) => {
        const orig = tgt.textContent
        tgt.textContent = value
        let newtime = ts2sec(vnode.dom.textContent) * 1000
        newtime = _.clamp(newtime, range.min, range.max)
        if (_.isFinite(newtime)) {
          time = newtime
        }
        tgt.textContent = orig
        onchange(time)
      }
      const sep = s => m('span.sep', s)
      return m('.timestamp-input', [
        m('input.is-hidden', { value: time, name }),
        (hours !== '00') && [
          unit({ digits: 2, value: hours, delta: 60 * 60e3, oninput: setTime }),
          sep(':')
        ],
        unit({ digits: 2, value: minutes, delta: 60e3, oninput: setTime }), sep(':'),
        unit({ digits: 2, value: seconds, delta: 1e3, oninput: setTime }), sep('.'),
        unit({ digits: 3, value: millis, delta: 10, oninput: setTime }),
        // unit({ digits: 1, value: Math.floor(millis / 100) % 10, delta: 100 }),
        // unit({ digits: 1, value: Math.floor(millis / 10) % 10, delta: 10 }),
        // unit({ digits: 1, value: millis % 10, delta: 1 }),
      ])
    }
  }
}

export const TimestampInput = m.isolate('.tinpt-wrap', TimestampInputInner)