import _ from 'lodash'
import StackTrace from 'stacktrace-js'

// browser specific
_.mixin({
  raf: () => new Promise(done => requestAnimationFrame(done)),
  isdev: () => process.env.NODE_ENV !== 'production',
  devlog: process.env.NODE_ENV === 'production' ? _.identity : (...args) => {
    const id = _.uniqueId('dev')
    console.log(`|===ENV: ${id}===|`, ...args)
    StackTrace.get().then(frames => {
      const { lineNumber, columnNumber, fileName, functionName } = frames[1]
      console.log(`|===FRM: ${id}===|`, fileName, 'at', [functionName, lineNumber, columnNumber].join(':'))
    })
  },
  isVnode: x => typeof x !== "object" || x.tag != null || Array.isArray(x),
})


const perflogs = {}
_.mixin({
  logstart: (key) => {
    const t = performance.now()
    perflogs[key] = t
  },
  logend: (key) => {
    const t = performance.now()
    if(key in perflogs) {
      console.log(key, ': time elapsed', t - perflogs[key])
    }
  },
})
