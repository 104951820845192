import _ from 'lodash'

m.link = (selector, ...args) => {
  let attrs = args[0], children = args

  // has attrs
  if (_.isPlainObject(attrs) && !_.isVnode(attrs)) {
    children = args.slice(1)
  } else {
    attrs = {}
  }

  return m(m.route.Link, Object.assign({ selector }, attrs), children)
}
