import feathers from '@feathersjs/feathers'
// import socketio from '@feathersjs/socketio-client'
import rest from '@feathersjs/rest-client'
import authentication from '@feathersjs/authentication-client'
import analytics from './analytics'
import _ from 'lodash'
// import io from 'socket.io-client'

// const socket = io();
const restClient = rest();
export const app = feathers();

// Set up Socket.io client with the socket
// app.configure(socketio(socket));
app.configure(restClient.fetch(window.fetch))

app.configure(authentication({
  storage: window.localStorage
}));

export const login = () => app.reAuthenticate()
export const logout = async (config = {}) => {
  const { reroute, redirect = '/' } = config
  await app.logout()
  analytics.reset()
  app.set('user', null)
  if(reroute) m.router.set(reroute)
  if(redirect) m.route.set(redirect)
}

export const grabToken = () => new Promise(done => {
  return login().then(() => done(true), () => done(false))
}).then(async result => {
  if (result) {
    const { user } = await app.get('authentication');
    app.set('user', user)
    analytics.identify(user.id, user)
  } else {
    app.set('user', null)
  }
  return result
})

export const request = async (config) => {
  const token = await app.authentication.getAccessToken()
  const t = token ? `Bearer ${token}` : ''
  _.set(config, 'headers.Authorization', t)
  return m.request(config)
}