import { BulmaForm, bulmaField } from "../../components/form"
import { bnav, page } from "../../components/flowbite/navbar"
import { app, request } from "../../utils/feathers"


export const StoryForm = () => {
  return {
    view: vnode => {
      const {
        editing = false,
        story
      } = vnode.attrs
      return m(BulmaForm, {
        data: story,
        onsubmit: async (e, data, valid) => {
          if (!valid) return
          let newstory
          if (editing) {
            newstory = await app.service('stories').update(story.id, _.merge(story, data))
          } else {
            newstory = await app.service('stories').create(data)
          }
          m.route.set(m.router.get('Story', {
            id: newstory.pubid
          }))
        }
      },
        m("h2.title.is-4", editing ? "Edit project" : "New project"),
        bulmaField({
          name: 'title',
          type: 'text',
          attrs: { required: true }
        }),
        bulmaField({
          name: 'description',
          type: 'textarea',
        }),
        m("div.field.is-grouped.mt-4",
          m("div.control.w-full",
            m("button.button.is-primary.w-1/4", "Save")
          ),
        )
      )
    }
  }
}


export default ({ redraw = m.redraw }) => {
  let editing
  let story
  return {
    oninit: async vnode => {
      editing = m.router.cur() === 'StoryFormEdit'
      if(editing) {
        const { id } = m.route.param()
        story = await app.service('stories').get(id, { query: { $client: { pubid: 1 } } })
        redraw()
      }
    },
    view: vnode => {
      const editing = m.router.cur() === 'StoryFormEdit'
      return page({
        class: '.story-form',
        navbar: bnav(),
        body: [
          m.n('.columns.my-4.is-centered > .column.is-7',
            m('.box',
              (!editing || (editing && story)) &&
              m(StoryForm, { editing, story })
            )
          )
        ]
      })
    }
  }
}