import _ from 'lodash'

_.mixin({
  pget: (obj, paths, defaultValue) => {
    for(let path of paths) {
      if(_.has(obj, path)) {
        return _.get(obj, path)
      }
    }
    return defaultValue
  },
  insertSorted: (a, v) => a.splice(_.sortedIndex(a, v), 0, v),
  insertSortedBy: (a, v, i) => a.splice(_.sortedIndexBy(a, v, i), 0, v),
  timeout: ms => new Promise(done => setTimeout(done, ms)),
  nest2dot: o => {
    const out = {}
    const inner = (o, prefix = '') => {
      if (_.isPlainObject(o) || _.isArray(o)) {
        for (let k in o) {
          inner(o[k], prefix ? prefix + '.' + k : k)
        }
      } else {
        out[prefix] = o
      }
    }
    inner(o)
    return out
  },
  dot2nest: o => {
    const out = {}
    for(let k in o) {
      _.set(out, k, o[k])
    }
    return out
  },
  // https://stackoverflow.com/questions/37128624/
  interpose: (arr, sep) => arr.reduce((a, v) => [...a, v, sep], []).slice(0, -1),
  count: (col, pred) => _.sumBy(col, _.flow(pred, Number)),
  strEquals: (s1, s2) => String(s1) === String(s2),
  roundNearest: (n, base=1) => Math.round(n / base) * base,
  roundDownNearest: (n, base=1) => Math.floor(n / base) * base,
  roundUpNearest: (n, base=1) => Math.ceil(n / base) * base,
  pp: (...args) => {
    console.log(...args.map(o => JSON.stringify(o, null, 2)))
    return _.last(args)
  },
});

// https://gist.github.com/Yimiprod/7ee176597fef230d1451?permalink_comment_id=3415430#gistcomment-3415430
const deepDiff = (fromObject, toObject, config = {}) => {
  const { traverseArrays } = config
  const changes = {};

  const buildPath = (path, obj, key) =>
    _.isUndefined(path) ? key : `${path}.${key}`;

  const walk = (fromObject, toObject, path) => {
    for (const key of _.keys(fromObject)) {
      const currentPath = buildPath(path, fromObject, key);
      if (!_.has(toObject, key)) {
        changes[currentPath] = { from: _.get(fromObject, key) };
      }
    }

    for (const [key, to] of _.entries(toObject)) {
      const currentPath = buildPath(path, toObject, key);
      if (!_.has(fromObject, key)) {
        changes[currentPath] = { to };
      } else {
        const from = _.get(fromObject, key);
        if (!_.isEqual(from, to)) {
          if (!traverseArrays && _.isArray(from) && _.isArray(to)) {
            const removed = _.differenceWith(from, to, _.isEqual)
            const added = _.differenceWith(to, from, _.isEqual)
            changes[currentPath] = { from, to, array: { removed, added } }
            return
          }
          if (_.isObjectLike(to) && _.isObjectLike(from)) {
            walk(from, to, currentPath);
          } else {
            changes[currentPath] = { from, to };
          }
        }
      }
    }
  };

  walk(fromObject, toObject);

  return changes;
}
_.mixin({ deepDiff })


const findSortedIndex = (arr, K, config = {}) => {
  const { eq = _.eq } = config
  let start = 0;
  let end = arr.length - 1;

  while (start <= end) {
    let mid = Math.floor((start + end) / 2);

    if (eq(arr[mid], K))
      return mid;

    else if (arr[mid] < K)
      start = mid + 1;

    else
      end = mid - 1;
  }

  // Return insert position
  return end + 1;
}
const binarySearchIndex = (arr, K, config) => {
  const idx = findSortedIndex(arr, K, config)
  return arr[idx] == K ? idx : -1
}
const binarySearch = (arr, K, config) => {
  const idx = binarySearchIndex(arr, K, config)
  return arr[idx]
}

_.mixin({ binarySearch, findSortedIndex, binarySearchIndex })