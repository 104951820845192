import SelfSufficient from './self-sufficient'
import './tailwind'
import './links'
import _ from 'lodash'

m.n = (tags, ...rest) => {
  const parts = tags.split('>')
  return parts.reduceRight((child, tag) => m(tag.trim(), child), m(parts.pop().trim(), ...rest))
}

_.set(m, 'helpers.SelfSufficient', SelfSufficient)

const isolate = (sel, compFn) => {
  return (vnode) => {
    let s, redraw = () => s && s.redraw()
    vnode.redraw = redraw
    const component = compFn(vnode)
    return {
      ...component,
      view: vnode => {
        return m(m.helpers.SelfSufficient, {
          root: m(sel),
          view: state => {
            s = state
            return component.view(vnode)
          }
        })
      }
    }
  }
}
_.set(m, 'isolate', isolate)
