import _ from 'lodash'
import { spinner } from './spinner'

const colors = {
  "default": "bg-blue-700 dark:bg-blue-600 dark:focus:ring-blue-800 dark:hover:bg-blue-700 focus:ring-blue-300 hover:bg-blue-800 mr-2 text-white",
  "alternative": "bg-white border border-gray-200 dark:bg-gray-800 dark:border-gray-600 dark:focus:ring-gray-700 dark:hover:bg-gray-700 dark:hover:text-white dark:text-gray-400 focus:ring-gray-200 focus:z-10 hover:bg-gray-100 hover:text-blue-700 mr-2 text-gray-900",
  "dark": "bg-gray-800 dark:bg-gray-800 dark:border-gray-700 dark:focus:ring-gray-700 dark:hover:bg-gray-700 focus:ring-gray-300 hover:bg-gray-900 mr-2 text-white",
  "light": "bg-white border border-gray-300 dark:bg-gray-800 dark:border-gray-600 dark:focus:ring-gray-700 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:text-white focus:ring-gray-200 hover:bg-gray-100 mr-2 text-gray-900",
  "green": "bg-green-700 dark:bg-green-600 dark:focus:ring-green-800 dark:hover:bg-green-700 focus:ring-green-300 hover:bg-green-800 mr-2 text-white",
  "red": "bg-red-700 dark:bg-red-600 dark:focus:ring-red-900 dark:hover:bg-red-700 focus:ring-red-300 hover:bg-red-800 mr-2 text-white",
  "yellow": "bg-yellow-400 dark:focus:ring-yellow-900 focus:ring-yellow-300 hover:bg-yellow-500 mr-2 text-white",
  "purple": "bg-purple-700 dark:bg-purple-600 dark:focus:ring-purple-900 dark:hover:bg-purple-700 focus:ring-purple-300 hover:bg-purple-800 text-white"
}

const disabled_colors = {
  "default": "bg-blue-400 dark:bg-blue-300 mr-2 text-white",
  "alternative": "bg-white border border-gray-200 dark:bg-gray-500 dark:border-gray-300    dark:text-gray-100 mr-2 text-gray-600",
  "dark": "bg-gray-500 dark:bg-gray-500 dark:border-gray-400 mr-2 text-white",
  "light": "bg-white border border-gray-300 dark:bg-gray-500 dark:border-gray-300 dark:text-white   mr-2 text-gray-600",
  "green": "bg-green-400 dark:bg-green-300 mr-2 text-white",
  "red": "bg-red-400 dark:bg-red-300 mr-2 text-white",
  "yellow": "bg-yellow-100 mr-2 text-white",
  "purple": "bg-purple-400 dark:bg-purple-300 text-white"
}


const outlines = {
  default: "border-blue-700 dark:border-blue-500 dark:focus:ring-blue-800 dark:hover:bg-blue-600 dark:text-blue-500 focus:ring-blue-300 hover:bg-blue-800 text-blue-700",
  dark: "border-gray-800 dark:border-gray-600 dark:focus:ring-gray-800 dark:hover:bg-gray-600 dark:text-gray-400 focus:ring-gray-300 hover:bg-gray-900 text-gray-900",
  green: "border-green-700 dark:border-green-500 dark:focus:ring-green-800 dark:hover:bg-green-600 dark:text-green-500 focus:ring-green-300 hover:bg-green-800 text-green-700",
  red: "border-red-700 dark:border-red-500 dark:focus:ring-red-900 dark:hover:bg-red-600 dark:text-red-500 focus:ring-red-300 hover:bg-red-800 text-red-700",
  yellow: "border-yellow-400 dark:border-yellow-300 dark:focus:ring-yellow-900 dark:hover:bg-yellow-400 dark:text-yellow-300 focus:ring-yellow-300 hover:bg-yellow-500 text-yellow-400",
  purple: "border-purple-700 dark:border-purple-400 dark:focus:ring-purple-900 dark:hover:bg-purple-500 dark:text-purple-400 focus:ring-purple-300 hover:bg-purple-800 text-purple-700"
}

const sizes = {
  "extra-small": "px-3 py-2 text-xs",
  "small": "px-3 py-2 text-sm",
  "base": "px-5 py-2.5 text-sm",
  "large": "px-5 py-3 text-base",
  "extra-large": "px-6 py-3.5 text-base"
}


export const button = (config = {}, children) => {
  const {
    color = 'default',
    rounded = false,
    outlined = false,
    size = 'base',
    iconLeft, iconRight, icon,
    label,
    loading = false,
    disabled = false
  } = config


  return m("button", {
    class: [
      "focus:outline-none focus:ring-4 font-medium mb-2",
      outlined && 'border dark:hover:text-white hover:text-white',
      disabled && "cursor-not-allowed text-center",
      outlined ?
        outlines[color] :
        disabled ?
          disabled_colors[color] :
          colors[color],
      rounded ? 'rounded-full' : 'rounded-lg',
      sizes[size],
      loading && 'inline-flex items-center'
    ].filter(x => x).join(' '),
    type: 'button',
    disabled: disabled || loading
  },
    loading && spinner({ color: 'white', twsize: 'h-4 w-4', currentFill: 'currentColor', currentColor: 'currentFill' }),
    children
  )
}