import { app } from "../../utils/feathers"
import * as timeago from 'timeago.js';
import { spinner } from "../../components/flowbite/spinner";
import { pager } from "../../components/pager";
import { data_pager } from "../../components/data_pager";


const storyItem = (story) => {
  const {
    title,
    description,
    createdAt,
    updatedAt
  } = story
  return m("article.media",
    m("div.media-content",
      m("div.content",
        m.link("a.font-bold.text-lg", {
          href: m.router.get('Story', { id: story.pubid })
        }, title),
      ),
    ),
    m("div.media-right",
      m.link("a.text-sm.text-blue-700.mr-2", {
        href: m.router.get('StoryFormEdit', { id: story.pubid })
      }, "Edit"),
      m("small.text-gray-400", 'updated ', timeago.format(updatedAt)),
      // m("span", createdAt),
      // m("button.delete")
    )
  )
}

export const StoryList = ({ redraw = m.redraw }) => {
  let stories
  return {
    oninit: async vnode => {
      const user = app.get('user')
      const $limit = _.clamp(m.route.param('limit') || 10, 2, 50)
      stories = await app.service('stories').find({
        query: {
          userId: user.id,
          $sort: {
            updatedAt: -1
          },
          $skip: (m.route.param('page') || 0) * $limit,
          $limit
        }
      })
      redraw()
    },

    view: vnode => {
      return data_pager(stories, {
        data_view: data => data.map(storyItem),
        onnav: async (i, query) => {
          m.router.setparam({ page: i })
          stories = await app.service('stories').find({ query })
          redraw()
        }
      })
    }
  }
}