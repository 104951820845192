import posthog from 'posthog-js'
import { slugify } from '../text'

// on posthog client lib:
// https://posthog.com/docs/libraries/js

// on this plugin
// https://github.com/DavidWells/analytics/issues/69
export default function postHogPlugin(userConfig) {
  // return object for analytics to use
  let isPostHogLoaded = false
  const ssid = '__ph__'
  return {
    /* All plugins require a name */
    name: 'posthog',
    /* Everything else below this is optional depending on your plugin requirements */
    config: { ...userConfig },
    initialize: ({ config }) => {
      // load provider script to page. https://posthog.com/docs/integrations/js-integration#installation
      posthog.init(config.token, {
        api_host: config.api_host,
        disable_session_recording: config.disable_session_recording,
        autocapture: config.autocapture,
        loaded: function(posthog) {
          isPostHogLoaded = true
        },
        // prevent double capture, see page event below
        capture_pageview: false
      })
    },
    page: ({ payload }) => {
      // call provider specific page tracking
      posthog.capture('$pageview');
    },
    track: ({ payload }) => {
      // call provider specific event tracking
      posthog.capture(payload.event, payload.properties);
    },
    identify: ({ payload }) => {
      if(!sessionStorage[ssid]) {
        sessionStorage[ssid] = true
        const { traits } = payload
        const userId = `${traits.id},${slugify(traits.google.name)}`
        posthog.identify(userId, {
          ..._.pick(traits, ['google', 'properties']),
          name: userId
        });
      }
    },
    reset: () => {
      delete sessionStorage[ssid]
      posthog.reset();
    },
    loaded: () => {
      // return boolean so analytics knows when it can send data to third party
      return isPostHogLoaded
    }
  }
}

