$(() => {
  const dropdownSel = '.dropdown:not(.is-hoverable)'

  function closeDropdowns() {
    for (let $el of document.querySelectorAll(dropdownSel)) {
      $el.classList.remove('is-active');
    }
  }

  document.addEventListener('click', function (event) {
    const $dropdown = event.target.closest(dropdownSel)

    if ($dropdown) {
      // event.stopPropagation();
      $dropdown.classList.toggle('is-active');
    } else {
      closeDropdowns();
    }
  });

  // Close dropdowns if ESC pressed
  document.addEventListener('keydown', function (event) {
    var e = event || window.event;
    if (e.code === 'Escape') {
      event.stopPropagation();
      closeDropdowns();
    }
  });
})