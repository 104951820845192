export const pager = (result, config = {}) => {
  const {
    next,
    prev,
    page,
    pages,
    query,
  } = result.pager
  const {
    onnav = console.log
  } = config

  return m("nav.pagination[role='navigation'][aria-label='pagination']",
    prev && m("a.pagination-previous.text-gray-700",
      { onclick: e => onnav(page - 1, { ...query, ...prev }) },
      m("i.fas.fa-arrow-left.mr-2"),
      "Prev"
    ),
    next && m("a.pagination-next.text-gray-700",
      { onclick: e => onnav(page + 1, { ...query, ...next }) },
      "Next",
      m("i.fas.fa-arrow-right.ml-2")
    ),
    m("ul.pagination-list",
      (new Array(pages)).fill(0).map((ignore, i) => {
        const pagenum = i + 1
        const current = page === i
        const attrs = {
          'aria-label': current ? `Page ${pagenum}` : `Goto page ${pagenum}`,
          onclick: e => onnav(i, { ...query, $skip: i * result.limit, $limit: result.limit })
        }
        if(current) {
          attrs.class = 'is-current'
          attrs['aria-current'] = 'page'
        }
        return m("li", m("a.pagination-link", attrs, pagenum))
      })
    )
  )
}