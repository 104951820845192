export const closableHeader = (config = {}) => {
  const {
    title,
    onclose = _.devlog
  } = config
  return m('nav.level.is-mobile',
    m.n('.level-left > .level-item', title),
    m.n('.level-right > .level-item',
      m("button.button.delete.force-m-0", { onclick: onclose })
    )
  )
}

const cmpify = view => () => ({ view })

export const Card = ({ redraw = m.redraw }) => {
  return {
    view: vnode => {
      const {
        cardattrs = {},
        box
      } = vnode.attrs
      return m(box ? '.box' : '.card', cardattrs, vnode.children)
    }
  }
}

Card.Title = cmpify(vnode => {
  return m('.title.is-4', vnode.children)
})

Card.Header = cmpify(vnode => {
  let {
    onclose,
    left,
    right
  } = vnode.attrs
  if (onclose) right = m("button.button.delete.force-m-0", { onclick: onclose })
  return m('.card-content.border-b.py-4',
    right ?
      m('nav.level.is-mobile',
        m.n('.level-left > .level-item', left || vnode.children),
        m.n('.level-right > .level-item', right)
      ) :
      vnode.children
  )
})

Card.Section = cmpify(vnode => {
  return m('.card-content', vnode.children)
})