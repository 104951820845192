import $ from 'jquery'
import 'parsleyjs'
import './garlic'

// kvs map from name to value
export const loadForm = (domform, kvs) => {
  for(let k in kvs) {
    let v = kvs[k]
    const dom = domform.querySelector(`[name="${k}"]`)
    if(dom) {
      dom.value = v
      if(['checkbox', 'radio'].includes(dom.getAttribute('type'))) {
        dom.checked = v
      }
    }
  }
}

export const serializeForm = (domform) => {
  const data = $(domform).serializeArray().reduce((o,v) => {
    const { name, value } = v
    o[name] = value
    return o
  }, {})

  $(domform)
    .find(`input[type='checkbox']`)
    .each((i, el) => {
      const name = $(el).attr('name')
      data[name] = $(el).is(':checked')
    })

  return data
}

export const getSelectText = (select) => {
  if (select.selectedIndex == -1)
    return null;
  return select.options[select.selectedIndex].text
}
