import _ from 'lodash'

m.tw = (selector, ...args) => value => {
  let attrs = args[0], children = args

  // has attrs
  if (_.isPlainObject(attrs) && !_.isVnode(attrs)) {
    children = args.slice(1)
  } else {
    attrs = {}
  }

  if (_.isPlainObject(value)) {
    return m(selector, Object.assign({}, attrs, _.omit(value, 'children')), children.concat(value.children))
  }
  return m(selector, attrs, children.concat(value))
}
