import $ from 'jquery'
import _ from 'lodash'

export const sec2ts = (sec, notrim) => {
  try {
    const d = new Date(Math.round(sec * 1000))
    const ts = d.toISOString().split('T')[1].split('Z')[0]
    return (!notrim && ts.startsWith('00:')) ? ts.substring(3) : ts
  } catch (error) {
    return error
  }
}
export const ts2sec = (str) => {
  if(str && str.split(':').length === 2) {
    str = '00:' + str
  }
  const d = new Date(`1970-01-01T${str}Z`)
  return d.getTime() / 1000
}
export const seek = (v, t) => new Promise(done => {
  if(v.currentTime === t) return
  $(v).on('seeked', () => {
    $(v).off('seeked')
    done()
  })
  v.currentTime = t
})

export const timestr = sec => {
  const ts = sec2ts(Math.floor(sec))
  return ts.substring(0, ts.length - 4)
}