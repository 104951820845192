import Analytics from 'analytics'

import googleAnalytics from '@analytics/google-analytics'
// import mixpanelPlugin from '@analytics/mixpanel'
import posthog from './posthog'


const analytics = Analytics({
  app: process.env.APP_NAME,
  plugins: [
    location.host === process.env.HOSTNAME &&
    posthog({
      api_host: process.env.PH_HOST,
      token: process.env.PH_TOKEN,
      autocapture: false,
      disable_session_recording: true
    }),
    // location.host === process.env.HOSTNAME &&
    // mixpanelPlugin({
    //   token: 'f82979ce41db4e23c488473c29c70dc7'
    // }),
    // googleAnalytics({
    //   trackingId: process.env.GA_TOKEN,
    // })
  ].filter(x => x)
})

export default analytics
