// https://gist.github.com/mathewbyrne/1280286
export const slugify = (text) => {
  if(!text) return ''
  return text.toString().toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
}

// https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
export const makePasscode = (length, characters='0123456789') => {
  var result           = []
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    var randchar = characters.charAt(Math.floor(Math.random() * charactersLength));
    result.push(randchar)
  }
  return result.join('');
}

