const _ = require('lodash')

const routes = {
  '/': { name: 'Landing', title: 'Story Prisma' },
  '/story/new': { name: 'StoryForm' },
  '/story/:id/edit': { name: 'StoryFormEdit' },
  '/story/:id': { name: 'Story' },
  // '/story/:id/storyboard': { name: 'Storyboard' },
  // '/story/:id/drive': { name: 'Drive' },
  '/story/:id/add': { name: 'Ingest' },
  '/story/:id/prompt': { name: 'PromptForm' },
  // '/story/:id/timeline': { name: 'Timeline' },
  '/story/:id/overlay/audio': { name: 'OverlayAudio' },
  '/story/:id/overlay/visual': { name: 'OverlayVisual' },
  '/story/:id/export': { name: 'ExportStory' },
  '/admin': { name: 'Admin' },
  '/tw-help': { name: 'TwHelp' },
}

/*
  // loads data for meta content
  loaders: [{
    type: 'feathers',
    seo: 'story',
    cmd: params => {
      return {
        model: 'stories',
        method: 'get',
        params: {},
        data: {},
        id: 0
      }
    }
  }],
  seo: {
    // all: function or string
    title: (params, data) => _.get(params, 'title', 'Story Prisma'),
  },
  client: {
    // or a function returning a boolean
    auth: true,
    // auth: (user, params, data) => ...,
  },
*/

module.exports = _.keys(routes).map(route => {
  const data = routes[route]
  _.set(data, 'seo.title', data.title)
  _.set(data, 'client.auth', data.auth)
  return Object.assign({ route }, data)
})