export const dropdown = (config = {}) => {
  const {
    trigger,
    triggerattrs = {},
    content
  } = config
  return m(".dropdown", {
    class: config.class,
    // oncreate: vnode => {
    //   const $el = $(vnode.dom)
    //   $el.find('.dropdown-trigger').on('click', () => $el.toggleClass('is-active'))
    //   $el.find('.dropdown-trigger > *').attr('aria-haspopup', true)
    // }
  }, [
    m(".dropdown-trigger.cursor-pointer", triggerattrs, trigger),
    m(".dropdown-menu[role='menu']", content)
  ])
}

export const Dropdown = () => {
  return {
    view: vnode => {
      const {
        id = _.uniqueId('dropdown-menu'),
        text,
        items = []
      } = vnode.attrs
      return m(".dropdown", _.omit(vnode.attrs, ['id', 'items']),
        vnode.children ?
          m(".dropdown-trigger[aria-haspopup='true']", { 'aria-controls': id }, vnode.children) :
          m.n(".dropdown-trigger > button.button[aria-haspopup='true']", { 'aria-controls': id },
            m("span", text),
            m("span.icon.is-small", m("i.fas.fa-angle-down[aria-hidden='true']"))
          ),
        m(".dropdown-menu[role='menu']", { id },
          m(".dropdown-content", items)
        )
      )
    }
  }
}
