import * as icons from "../icons"
import * as authbtns from '../auth_btns'
import { app, logout } from "../../utils/feathers"
import { dropdown } from "../dropdown"

export const logo = (config = {}) => {
  const { name = 'StoryPrisma', href='/', img } = config
  return m(m.route.Link, { href, class: "flex items-center text-gray-700" },
    m("img.mr-3.h-6.sm:h-9[src='https://flowbite.com/docs/images/logo.svg']", {
      alt: `${name} logo`
    }),
    m("span.self-center.text-xl.font-semibold.whitespace-nowrap.dark:text-white",
      name
    )
  )
}

export const mobileMenu = ({ menuid }) => {
  return m("button.inline-flex.items-center.p-2.ml-3.text-sm.text-gray-500.rounded-lg.md:hidden.hover:bg-gray-100.focus:outline-none.focus:ring-2.focus:ring-gray-200.dark:text-gray-400.dark:hover:bg-gray-700.dark:focus:ring-gray-600[type='button'][aria-expanded='false']", {
    "data-collapse-toggle": menuid,
    "aria-controls": menuid
  },
    m("span.sr-only",
      "Open main menu"
    ),
    icons.hamburger({ class: 'w-6 h-6' }),
    icons.close({ class: 'hidden w-6 h-6' })
  )
}

export const navlink = ({ active, text }) => {
  return m("li",
    active ?
      m.tw("a.block.py-2.pr-4.pl-3.text-white.bg-blue-700.rounded.md:bg-transparent.md:text-blue-700.md:p-0.dark:text-white[href='#'][aria-current='page']")(text) :
      m.tw("a.block.py-2.pr-4.pl-3.text-gray-700.border-b.border-gray-100.hover:bg-gray-50.md:hover:bg-transparent.md:border-0.md:hover:text-blue-700.md:p-0.dark:text-gray-400.md:dark:hover:text-white.dark:hover:bg-gray-700.dark:hover:text-white.md:dark:hover:bg-transparent.dark:border-gray-700[href='#']")(text)
  )
}

export const navbar = (config = {}) => {
  const {
    tw,
    container
  } = config
  const menuid = 'mobile-menu'
  return m("nav.bg-white.border-gray-200.px-2.sm:px-4.py-2.5.rounded.dark:bg-gray-800", { class: tw },
    m("div.flex.flex-wrap.justify-between.items-center", {
      class: container && 'container mx-auto',
      oncreate: vnode => {
        $(`[data-collapse-toggle="${menuid}"]`).on('click', () => {
          $(`#${menuid}`).toggleClass('hidden')
        })
      }
    },
      [
        logo(),
        mobileMenu({ menuid }),
        m(".hidden.w-full.md:block.md:w-auto", { id: menuid },
          m("ul.flex.flex-col.mt-4.md:flex-row.md:space-x-8.md:mt-0.md:text-sm.md:font-medium",
            [
              navlink({ active: true, text: "Home" }),
              navlink({ text: "About" }),
              m('.pr-2',
                authbtns.google()
              )
            ]
          )
        )
      ]
    )
  )
}



const userMenu = user => {
  return dropdown({
    class: 'is-right',
    trigger: m("img", { class: 'w-6 h-6 rounded-full', src: user.profilePicture }),
    content: m(".dropdown-content",
      m(".dropdown-item.font-bold",
        _.get(user, 'google.name')
      ),
      m("hr.dropdown-divider"),
      m("a.dropdown-item",
        " Other dropdown item "
      ),
      m("a.dropdown-item[href='#']", // { class: 'is-active' },
        " Active dropdown item "
      ),
      m("a.dropdown-item[href='#']",
        " Other dropdown item "
      ),
      m("hr.dropdown-divider"),
      m("a.dropdown-item.has-text-danger", { onclick: e => logout({ reroute: 'Landing' }) },
        " Sign out "
      )
    )
  })
}

export const bnav = () => {
  const user = app.get('user')
  const menuId = 'main-nav'
  return m("nav.navbar[role='navigation'][aria-label='main navigation']", {
    class: "border-b",
    oncreate: vnode => {
      const $el = $(vnode.dom)
      $el.find(".navbar-burger").on('click', function () {
        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        $el.find(".navbar-burger").toggleClass("is-active");
        $el.find(".navbar-menu").toggleClass("is-active");
      });
    }
  },
    m(".navbar-brand", [
      m.link('a.navbar-item', { "href": "/" },
        m("img.px-2", { "src": "https://flowbite.com/docs/images/logo.svg" }),
        m(".font-semibold.subtitle", "StoryPrisma")
      ),
      m("a.navbar-burger[role='button'][aria-label='menu'][aria-expanded='false']", {
        'data-target': menuId
      }, [
        m("span[aria-hidden='true']"),
        m("span[aria-hidden='true']"),
        m("span[aria-hidden='true']")
      ])
    ]),
    m(".navbar-menu", { id: menuId }, [
      m("div.navbar-start",
        m("a.navbar-item", " Stories "),
        m("a.navbar-item", " Templates "),
      ),
      m("div.navbar-end",
        m("div.navbar-item",
          m("div.buttons.is-right",
            // m("a.button.is-primary",
            //   m("strong", "Sign up")
            // ),
            // m("a.button.is-light", " Log in "),
            // m("a.button.is-primary", " Sign in "),
            m(".mb-2",
              user ?
                userMenu(user) :
                authbtns.google()
            )
          )
        )
      )
    ])
  )
}


export const page = (config = {}) => {
  const {
    navbar,
    body,
    bodyCls = 'p-4'
  } = config
  return m(".page.flex.flex-col.h-screen", { class: config.class },
    navbar,
    m(".bg-gray-100.flex-1", {
      class: bodyCls
    }, body)
  )
}