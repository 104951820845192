import './form.scss'
import { serializeForm, loadForm } from "../utils/form"
import _ from 'lodash'
import { TimestampInput } from './inputs/timestamp'

export const BulmaForm = () => {
  return {
    view: vnode => {
      const {
        onsubmit = console.log,
        parsley = true
      } = vnode.attrs

      return m("form", {
        onsubmit: e => {
          e.preventDefault()
          const data = serializeForm(e.target)
          if (parsley) {
            $(vnode.dom).parsley().whenValid()
              .then(
                () => onsubmit(e, data, true),
                () => onsubmit(e, data, false),
              )
          } else {
            onsubmit(e, data)
          }
        }
      }, vnode.children)
    },
    oncreate: vnode => {
      const {
        data = {},
        parsley = true,
        garlic = true
      } = vnode.attrs
      if(_.isEmpty(data)) {
        const firstInput = _.first(vnode.dom.querySelectorAll('input, textarea'))
        firstInput && firstInput.focus()
      } else {
        loadForm(vnode.dom, data)
      }
      parsley && $(vnode.dom).parsley()
      garlic && $(vnode.dom).garlic()
    }
  }
}

export const bulmaField = (config = {}) => {
  const {
    name,
    label = _.capitalize(name),
    type = 'text',
    placeholder,
    attrs = {},
    controlattrs = {},
    data = {},
    addons = {},
    custominput,
    allowNull = true
  } = config
  let input = m("input.input", { type, placeholder, name, ...attrs })
  let labeled_input = false
  switch(type) {
    case 'textarea':
      input = m("textarea.textarea", { placeholder, name, ...attrs })
      break;
    case 'select':
      input = m(".select", data.attrs || {},
        m("select", { name, ...attrs },
          data.options.map(option => {
            return m('option', { value: option.value }, option.text)
          })
        )
      )
      break;
    case 'checkbox':
      labeled_input = true
      input = m('label.checkbox', m("input", { type, name, ...attrs }), m('span.ml-2', label))
      break;
    case 'timestamp':
      input = m('.input', data.attrs || {},
        m(TimestampInput, { name, ...attrs })
      )
      break;
    case 'custom':
      input = custominput
    default:
      break;
  }
  return m("div.field", { class: !_.isEmpty(addons) && 'has-addons' },
    addons.left,
    (label && !labeled_input) && m("label.label", label),
    m("div.control", controlattrs, input),
    addons.right,
  )
}