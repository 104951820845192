import { button } from '../components/flowbite/button'
import { bnav, navbar, page } from '../components/flowbite/navbar';
import { StoryList } from './story/list';
import { Card } from '../components/card';
import { app } from '../utils/feathers';

export default function () {
  return {
    view() {
      const user = app.get('user')
      return page({
        class: 'landing',
        navbar: bnav(),
        body: m.n(".columns.my-4.is-centered > .column.is-7", [
          m(Card, { cardattrs: { class: 'mb-6' } },
            m(Card.Header, m(Card.Title, 'Temp landing page...')),
            m(Card.Section,
              m('.buttons',
                m.link('a.button.is-primary', {
                  href: m.router.get('StoryForm')
                }, 'New Story'),
                m.link('a.button.is-outlined', {
                  href: m.router.get('TwHelp')
                }, 'Tailwind Help'),
              ),
            )
          ),
          user && m(Card,
            m(Card.Header, m(Card.Title, 'Your stories')),
            m(Card.Section, m(StoryList))
          ),
        ])
      });
    },
  };
}
