import 'core-js/stable';
import 'regenerator-runtime/runtime';

/* Include global app styles here, so that it will over ride component's css styles*/
import './app.scss';

if (module.hot) {
  module.hot.accept();
}

if (process.env.NODE_ENV !== 'production') {
  console.log('Looks like we are in development mode!');
  // if (window.location.href.indexOf("localhost") >= 0) { //customize it to your needs
  //   document.head.appendChild(document.createElement("script")).src = "//r8s.io"
  // }
}

console.log('Env var test ===>', process.env.BASE_URL, process.env.BASE_URL_EXPAND);
import './utils/lodash-mixins'
import './utils/lodash-mixins-browser'
import './utils/mithril'
import './utils/bulma'
import './app'