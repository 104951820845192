import route_config from './route-config'
import BiMap from 'bidirectional-map'
import { PageRegistry } from './pages'
import { grabToken } from './utils/feathers'
import analytics from './utils/analytics'
import _ from 'lodash'

export const routes = new BiMap()
route_config.forEach(r => {
  const { name, route } = r
  routes.set(name, route)
})

let lastRoute
_.set(m, 'router.get', (routename, params) => {
  return params ?
    m.buildPathname(routes.get(routename), params) :
    routes.get(routename)
})
_.set(m, 'router.cur', () => {
  return routes.getKey(lastRoute || m.route.get())
})
_.set(m, 'router.set', (...args) => {
  args[0] = routes.get(args[0])
  return m.route.set(...args)
})
_.set(m, 'router.setparam', (param, overwrite) => {
  const route = m.router.cur()
  return m.router.set(route, overwrite ? param : _.merge(m.route.param(), param))
})
_.set(m, 'router.rmparam', (params) => {
  const route = m.router.cur()
  return m.router.set(route, _.omit(m.route.param(), params))
})


const handleRouteData = (route_config, components) => {
  const Routes = {}
  route_config.forEach(rd => {
    const { name, route } = rd
    const fetchedData = {}

    Routes[route] = {
      // args: routing params
      // requestedPath: new path with params
      // route: route
      onmatch: async (args, requestedPath, route) => {
        const routeName = routes.getKey(route)

        const { auth, url, preload } = PageRegistry.getPolicy(routeName)

        if (auth) {
          const { allow, redirectPage, skipRedirect } = auth
          const allowed = await allow()

          if (!allowed) {
            if (skipRedirect) return m.route.SKIP
            m.router.set(redirectPage)
          }
        }
        if (url) {
          const { validate } = url
          const valid = await validate()
          // https://mithril.js.org/route.html#typed-routes
          // typed routes: validate route parameters
          if (!valid) {
            return m.route.SKIP // pretend a route is 404
          }
        }
        if (preload) {
          const { fetch } = preload
          const data = await fetch()
          // TODO: how to pass data to component?
          // when preloading data, pass 404s to NotFound
        }

        // analytics
        // timeout to wait after url changes
        setTimeout(() => analytics.page(), 0)

        lastRoute = route

        // registry handles code splitting
        return PageRegistry.get(routeName)
      },
      render: vnode => {
        // handle document title
        let title = _.get(rd, 'seo.title')
        if (title) {
          if (_.isFunction(title)) {
            title = title(vnode.attrs, { ...fetchedData, url: location.href })
          }
          document.title = title
        }

        return vnode
      }
    }
  })
  return Routes
}

m.route.prefix = ''

const Routes = handleRouteData(route_config)
const DefaultRoute = '/';

// export { Routes, DefaultRoute };

// mount the app
grabToken().then(hasToken => {
  const $root = document.body.querySelector('#root');
  m.route($root, DefaultRoute, Routes);

  if (sessionStorage.returnToPage) {
    m.route.set(sessionStorage.returnToPage)
    delete sessionStorage.returnToPage
  }
})
