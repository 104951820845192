import _ from 'lodash'
import { app, request } from '../utils/feathers'
import Landing from './landing'
import StoryForm from './story/form'

const pages = {
  Landing,
  StoryForm,
  StoryFormEdit: StoryForm,
  Story: { load: () => import('./story') },
  Ingest: { load: () => import('./storyboard/ingest') },
  PromptForm: { load: () => import('./storyboard/prompt') },
  OverlayAudio: { load: () => import('./storyboard/overlays/audio') },
  OverlayVisual: { load: () => import('./storyboard/overlays/visual') },
  ExportStory: { load: () => import('./story/exporter') },
  // Storyboard: { load: () => import('./storyboard') },
  // Drive: { load: () => import('./storydrive/view') },
  Admin: { load: () => import('./admin') },
  TwHelp: { load: () => import('./utils/tw-help') }
}

const policies = {
  Admin: {
    auth: {
      allow: () => request({ url: '/api/admin/authorized' }),
      skipRedirect: true
    }
  }
}

const default_policies = {
  auth: {
    allow: () => !!app.get('user'),
    redirectPage: 'Landing'
  }
}

export const PageRegistry = {
  get: async (routeName) => {
    const cmp = pages[routeName]
    if (cmp.load) {
      const module = await cmp.load()
      return _.get(module, cmp.path || 'default')
    }
    return cmp
  },
  getPolicy: routeName => {
    const policy = policies[routeName]
    return policy ? _.merge(_.cloneDeep(default_policies), policy) : {}
  }
}